import React from 'react'

import Background from '../images/doctor-background.png'

const Policies = () => {
    return (
        <div>
            <div className='section is-medium' style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}>
                <h1 className='title has-text-centered has-text-white'>Policies</h1>
            </div>
            <div className='section has-background-light'>
            <div className='container is-flex is-justify-content-center '>
                    <div>
                        <p className='content has-text-centered mx-6'>The Medical Literacy and Access Project adheres to the following policies:</p>
                        <p className='content has-text-centered mx-6'><b>Privacy & Security:</b> Your privacy are important to us. We promise to our website visitors that we do not gather information on your online activities, including cookies, and we do not sell or share your information. We are in complete compliance with the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and do not collect any protected health information. Any protected health information that is submitted through our contact form will be permanently deleted to protect your privacy, and we will contact you to confirm this. If you have questions about our website's privacy and security, please <a href="/contact">contact us</a>. We will be happy to assist you.</p>
                        <p className='content has-text-centered mx-6'><b>Terms & Conditions:</b> By accessing and using this website, you accept the contents of this agreement, which is permanently visible on this website. We may modify this agreement at any time, effectively immediately upon publication. You agree to review the terms & conditions posted on this website each time you use our services so that you are aware of any modifications made to this agreement. We provide services that help you access healthcare services, including patient guides and search tools. You agree that you will not transmit any communications or content that infringe upon, misappropriate, or violate the rights of any party. You agree that transmitting unlawful, obscene, defamatory, predatory, threatening, harrassing, abusive, slanderous, or hateful material, using services in a away that is intended to harm the user or others, collecting information about others, and intentionally distributing viruses or other harmful computer code shall constitute a material breach of these terms & conditions.</p>
                        <p className='content has-text-centered mx-6'>The Medical Literacy and Access Project expressly reserves the right, in its sole discretion, to terminate a user's access to any areas of the website due to any act that would constitute a violation of these terms & conditions. This website provides links to other third party websites solely as a convenience to you. We are not responsible for the content, security, or privacy practices of third party websites; such links do not constitute or imply endorsement by the Medical Literacy and Access Project. We do not receive payment or other remuneration in exchange for linking to any third party website. </p>
                        <p className='content has-text-centered mx-6'>Although we attempt to maintain the integrity and accuracy of the information on the website, we make no guarantees as to its correctness, completeness, or accuracy. Unauthorized additions, deletions, or alterations could be made to the website by third parties without our knowledge; if you believe this is the case, please inform us by <a href="/contact">contacting us</a>. All contents on the website, unless otherwise indicated, are the property of the Medical Literacy and Access Project and its licensors and is protected by United States and international copyright laws. Any use of these materials on other websites or networked computer environments for any purpose is prohibited. You acknowledge that accessing the Service is subject to the U.S. Export Administrative Regulations (15CFR, Chapter VII) and that you will comply with these regulations.</p>
                        <p className='content has-text-centered mx-6'>You will not export or re-export this technology, directly or indirectly, to: (1) countries that are subject to U.S. export restrictions; (2) any end user who has been prohibited from participation in U.S. export transactions by any federal agency of the U.S. government; or (3) any end user who you know or have reason to know will use them in any way that is not allowed by U.S. laws. You further acknowledge the Service may include technical data subject to export and re-export restrictions imposed by U.S. law. By contacting us, you agree to receive a response communication by email or phone call. Our website is designed for educational purposes only and should not be used as a substitute for personal medical attention, diagnosis, or hands-on treatment. If you are concerned about your health or that of a child, please consult your health provider immediately. Do not wait for a response from our team.</p> 
                        <p className='content has-text-centered mx-6'>The Medical Literacy and Access Project and other third parties mentioned or linked to on the websites are neither responsible nor liable for any direct, indirect, incidental, consequential, special, exemplary, punitive, or other damages arising out of or relating in any way to the website, site-related services and products, content or information contained within the website, and/or any third party website, whether based on warranty, contract, tort, or any other legal theory and whether or not advised of the possibility of such damages. Your sole remedy for dissatisfaction with the website, site-related services, and/or third party websites is to stop using the website and/or those services. Applicable law may not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you. This agreement is governed by Rhode Island law.</p>
                        <p className='content has-text-centered mx-6'><b>Americans with Disabilities Act (ADA) Notice:</b> In accordance with the requirements of title II of the Americans with Disabilities Act of 1990 ("ADA"), the Medical Literacy and Access Project will not discriminate against qualified individuals with disabilities on the basis of disability in its services, programs, or activities. Upon request, we will provide appropriate aids and services leading to effective communication for qualified persons with disabilities so they can participate equally in programs, services, and activities. We will make all reasonable modifications to policies and programs to ensure that people with disabilities have an equal opportunity to enjoy all of our programs and services. Anyone who requires an auxiliary aid, service, or modification of policies or procedures should <a href="/contact">contact us</a> as soon as possible. There will be no surcharge for covering the costs of providing auxiliary aids, services, or reasonable modifications of policy.</p>
                        <p className='content has-text-centered mx-6'><b>Non-Discrimination Statement:</b> The Medical Literacy and Access Project strives to help provide access to barrier-free, all-inclusive healthcare for all patients. We comply with applicable federal civil rights laws. No one is nor will be denied or excluded from access to services or programs on the basis of disability, race, gender identity, ethnicity, color, religion, sex, sexual orientation, origin, age, or socioeconomic status. We do not and will not discriminate on the basis of disability, race, gender identity, ethnicity, color, religion, sex, sexual orientation, origin, age, or socioeconomic status.</p>
                        <p className='content has-text-centered mx-6'><b>Conflict of Interest:</b> All individuals in the Medical Literacy and Access Project (MLAP) community are expected to fully and continually disclose professional and relevant personal activities and relationships that create a conflict of interest or have the appearance of creating a conflict of interest as required by the project, to remedy conflicts of interest or comply with any management or monitoring plan prescribed by the project, remain aware of the potential for conflicts of interest, and take initiative to manage, disclose, or resolve conflicts of interest as appropriate. All participants have the responsibility to understand and implement this policy, including, as necessary, the adoption of specific procedures for their respective departments in furtherance of and in accordance with this policy. Conflicts of interest are defined as situations in which financial, professional, or other personal considerations may compromise, or may have the appearance of compromising an individual's professional judgment in exercising MLAP duties and responsibilities. A COI arises when a Covered Individual or MLAP has a direct or indirect interest in an organization collaborating with MLAP.</p>
                        <p className='content has-text-centered mx-6'>Any changes to our website's policies will be posted and dated. <i>Last updated on November 1, 2022.</i></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Policies
